<template>
  <div class="container">
    <div class="head">
      <div>
        <div class="dataOper">
          <div class="OperButton" id="btnend">
            <div class="addBtn" @click="addGoods">
              <img src="../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
            </div>
            <div class="uploadbtn" @click="uploadBtn">
              <input type="file" v-show="false" ref="file" @change="handleClick" />
              <i style="margin-right: 5px">
                <img src="../../assets/images/leading.png" style="width: 12px;height: 12px;" alt="" /> </i>批量导入
            </div>
            <div class="downloadbtn" @click="downloadBtn">
              <i class="el-icon-download" style="font-size: 18px;"></i>下载模板
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="text">
        <!-- 查询 -->
        <div class="mine">
          <el-form style="margin-bottom: -10px;" :inline="true" class="demo-form-inline" :model="ruleForm">
            <el-form-item label="商品分类">
              <div class="block">
                <el-cascader style="width: 260px;margin-right: 10px;" v-model="ruleForm.categoryId"
                  :options="CategoryList" @change="handleChange"
                  :props="{ value: 'id', label: 'name', children: 'childList' }">
                </el-cascader>
              </div>
            </el-form-item>
            <el-form-item label="发布渠道" prop="channel">
              <el-select v-model="ruleForm.channel" placeholder="请选择发布渠道" style="width: 260px;margin-right: 10px;">
                <el-option value="" label="全部"></el-option>
                <el-option v-for="item in channelList" :value="item.id"  :key="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称" prop="title">
              <el-input placeholder="请输入内容" style="width: 260px;margin-right: 10px;" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="店铺名称">
              <el-select style="width: 260px;margin-right: 10px;" v-model="ruleForm.storeId" filterable
                placeholder="请选择店铺" :filter-method="filterData" ref="searchSelect">
                <el-option value="" label="全部"></el-option>
                <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div class="search_main" style="margin-top: 0px">
                <div class="status1" style="display: flex">
                  <div class="demandBtn" @click="search">
                    <img id="images" src="../../assets/images/search.png" alt="" />查询
                  </div>
                  <div class="resetBtn" @click="reset">
                    <img src="../../assets/images/reset.png" alt="" />重置
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 查询 -->
      </div>
    </div>

    <div style="margin-top: 5px;background-color: white;">

      <el-tabs style="padding: 5px;" v-model="ruleForm.goodsTabStatus" @tab-click="tagChenge">
        <el-tab-pane :label="`出售中(${TabStatus[1]})`" name="1" @click="ruleForm.goodsTabStatus = '1'"></el-tab-pane>
        <el-tab-pane :label="`全部(${TabStatus[0]})`" name="0" @click="ruleForm.goodsTabStatus = '0'"></el-tab-pane>
        <el-tab-pane :label="`已售罄(${TabStatus[2]})`" name="2" @click="ruleForm.goodsTabStatus = '2'"></el-tab-pane>
        <el-tab-pane :label="`未上架(${TabStatus[3]})`" name="3" @click="ruleForm.goodsTabStatus = '3'"></el-tab-pane>
        <el-tab-pane :label="`待审核(${TabStatus[4]})`" name="4" @click="ruleForm.goodsTabStatus = '4'"></el-tab-pane>
      </el-tabs>
      <!-- 列表 -->
      <div class="body">
        <el-table ref="multipleTable" :data="tableData" style="font-family: PingFangRoutine;" tooltip-effect="dark" border
          @selection-change="handleSelectionChange" :header-cell-style="tableHeaderColor">
          <el-table-column type="selection" width="50" />
          <el-table-column prop="id" label="商品ID" width="180"  align="left" />
          <el-table-column prop="name" label="商品名称"  align="left"  >
            <template slot-scope="scope">
              <div class="overflow">{{ scope.row.name }}<br>{{scope.row.confirmGoodsChangeLog}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="storeName" width="150" label="店铺名称"  align="left" />
          <el-table-column label="商品图" width="80">
            <template slot-scope="scope">
              <div id="imagesbtn">
                <img :src="scope.row.mainImageUrl" alt="" />
                <div class="absPopup" v-if="scope.row.stock == 0">已售罄</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="costPriceStr" label="进货价" width="80"/>
          <el-table-column prop="marketPriceStr" width="80" label="批发价"/>
          <el-table-column prop="stock" label="库存" width="80"/>
          <el-table-column width="80" prop="saleFakeCount"  label="实际销量"/>
          <el-table-column prop="status" label="状态" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.status==0">待审核</span>
              <span v-show="scope.row.status==1">审核中</span>
              <span v-show="scope.row.status==2">审核通过</span>
            </template>
          </el-table-column>
          <el-table-column prop="enabled" label="上架下架"  width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enabled" active-color="rgb(64,158,255)" inactive-color="rgb(138,138,138)"
                @change="changeComposeStatus(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column  prop="invokeName" label="商品归属"  width="100">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template v-slot="scope">
              <el-button type="text"   @click="roleEdit(scope.row.id, scope.row.storeName)" >编辑</el-button>
              <el-button type="text"   @click="roleDel(scope.row.id)" >删除</el-button>
              <el-button type="text" v-if="scope.row.confirmGoodsChangeLog"  @click="confirmGoodsAction(scope.row)" >
                立即生效
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="margin-top: 3px;"></div>
    <!-- 列表 -->
    <div class="footer">
      <div id="cardfooter">
        <!-- 按钮 -->
        <div class="dataOper" id="dataOperbtn">
          <div class="OperButton" id="btnend">
            <div class="groundingbtn" @click="groundingAll">
              <img class="icontopimg" src="../../assets/images/top.png" alt="" />
              <span>批量上架</span>
            </div>
            <div class="undercarriagebtn" @click="undercarriageAll">
              <img class="icontopimg" src="../../assets/images/bottom.png" alt="" />
              <span>批量下架</span>
            </div>
            <div class="deletebtn" @click="roleDelAll">
              <i class="el-icon-delete" style="margin-right: 5px"></i>批量删除
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <div class="Pagination">
          <div class="Pagination_amin">
            <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10"
              layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
          <div class="Pagination_btn" style="height: 28px;">确定</div>
        </div>
        <!-- 分页 -->
      </div>
    </div>



    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :modal-append-to-body="false">
      <div style="width: 100%">
        <div class="prompt">
          <img src="../../assets/images/tishi.png" alt="" />
        </div>
        <p id="text">
          您已选中{{ num }}件商品,是否需要全部{{ ground ? "上架" : "下架" }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="Show" width="30%" :modal-append-to-body="false">
      <div style="width: 100%">
        <div class="prompt">
          <img src="../../assets/images/tishi.png" alt="" />
        </div>
        <p id="text">请选择您要删除的商品</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Show = false">取 消</el-button>
        <el-button type="primary" @click="Show = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  goodsList,
  goodsEnabled,
  goodsDelete,
  goodsCategoryTreeList,
  goodsUpLoad,
  goodsDownLoad,
  goodsTabStatus, confirmGoods,
} from "@/api/goods";
import {
  storeList
} from "@/api/store";
import "../../font-style/font.css";
export default {
  data() {
    return {
      TabStatus: [], //页签商品数量
      ground: true, //上下架控制
      num: 0, // 选中的数量
      dialogVisible: false, // 弹框显示隐藏
      isShow: true, // 编辑/添加 标识位
      tableData: [],
      ruleForm: {
        goodsTabStatus: "1", //tabs筛选
        categoryId: "", //二级分类id
        name: "", //商品名称
        storeId: "", //店铺id
        currPage: "1", //页码数
        channel:'' // 发布渠道
      },
      total: 0,
      CategoryList: [], // 分类一级列表
      arr: [], // 选中的每项id
      arrItem: [], //选中的每项数据
      Show: false, //批量删除判断提示
      storeList: [], //店铺列表
      storeQuery: {
        // 店铺列表请求参数
        currPage: 1,
        pageSize: 20,
        name: "",
      },
      channelList: [{
        id: 0,
        name: "全平台",
      },
      {
        id: 1,
        name: "pc端",
      },
      {
        id: 2,
        name: "移动端",
      },
      ], // 渠道列表
    };
  },
  activated() {
    this.list();
    this.store();
    // 分类列表
    goodsCategoryTreeList().then((res) => {
      this.CategoryList = res.data;
    });
    // 分类条数
    goodsTabStatus().then((res) => {
      this.TabStatus = res.data.map((item) => {
        return item.total;
      });
    });
  },
  methods: {
    tableHeaderColor({
      rowIndex
    }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px';";
      }
    },
    list() {
      goodsList(this.ruleForm).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
    },

    // 店铺列表
    store() {
      storeList(this.storeQuery).then((res) => {
        this.storeList = res.data.list;
      });
    },

    // 搜索框内容
    filterData(val) {
      this.storeQuery.name = val;
      this.store();
    },

    // 选择框改变
    handleChange(val) {
      this.ruleForm.categoryId = val[1];
    },

    //tab 被选中时触发
    tagChenge(tab, event) {
      this.list();
    },

    // 编辑
    roleEdit(id, storeName) {
      this.isShow = false;
      this.$router.push({
        path: "/goods/add",
        query: {
          isShow: this.isShow,
          id: id,
          storeName: storeName
        },
      });
    },

    async confirmGoodsAction(row){
      confirmGoods(row.id).then((res) => {
        this.$message.success("操作成功");
        this.list();
      });
    },
    //删除
    async roleDel(id) {
      if (!(await this._box())) return;
      let totalPage = Math.ceil((this.total - 1) / 10);
      let currentPage =
        this.ruleForm.currPage > totalPage ? totalPage : this.ruleForm.currPage;
      this.ruleForm.currPage = currentPage < 1 ? 1 : currentPage;
      goodsDelete({
        id
      }).then((res) => {
        this.list();
      });
    },

    // 添加
    addGoods() {
      this.isShow = true;
      this.$router.push({
        path: "/goods/add",
        query: {
          isShow: this.isShow
        },
      });
    },

    // 复选框改变触发
    handleSelectionChange(val) {
      this.num = val.length;
      this.arrItem = val;
      this.arr = val.map((item) => {
        return item.id;
      });
    },

    //批量删除
    async roleDelAll() {
      if (this.arr.length <= 0) {
        this.prompt()
      }
      if (this.arr.length > 0) {
        if (!(await this._box())) return;
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage =
          this.ruleForm.currPage > totalPage ?
            totalPage :
            this.ruleForm.currPage;
        this.ruleForm.currPage = currentPage < 1 ? 1 : currentPage;
        goodsDelete({
          id: this.arr.join(",")
        }).then((res) => {
          this.list();
        });
      } else {
        this.isShow = true;
      }
    },

    // 查询
    search() {
      this.ruleForm.currPage = ""
      this.total = 0
      goodsTabStatus(this.ruleForm).then((res) => {
        this.TabStatus = res.data.map((item) => {
          return item.total;
        });
      });
      this.list();
    },

    // 重置
    reset() {
      this.total = 0
      this.ruleForm.categoryId = "";
      this.ruleForm.channel = "";
      this.ruleForm.name = "";
      this.ruleForm.storeId = "";
      this.CategoryList = [];
      this.CategoryTwo = [];
      this.category = "";
      this.storeQuery.name = "";
      this.ruleForm.currPage = "";
      this.store();
      // 分类列表
      goodsCategoryTreeList().then((res) => {
        this.CategoryList = res.data;
      });
      this.list();
    },

    //分页
    handleCurrentChange(val) {
      this.ruleForm.currPage = val;
      this.list();
    },

    //上架下架
    changeComposeStatus(val) {
      if (val.mainImageUrl != "" && val.mainImageUrl != null) {
        goodsEnabled({
          idList: [val.id],
          enabled: val.enabled
        }).then((res) => {
          this.list();
        });
      } else {
        this.list();
        this.$message.error("未添加商品主图禁止上架!");
      }
    },
    //未选中提示
    prompt() {
      this.$confirm('请选择要操作的数据', '提示', {
        type: 'info',
      })
    },
    //批量下架
    undercarriageAll() {
      if (this.arr.length <= 0) {
        this.prompt()
      } else {
        this.ground = false;
        this.dialogVisible = true;
      }
    },
    //批量上架
    groundingAll() {
      if (this.arr.length <= 0) {
        this.prompt()
      } else {
        this.ground = true;
        this.dialogVisible = true;
      }

    },

    // 弹出框确认
    confirm() {
      if (this.ground) {
        var b = this.arrItem.every(function (item) {
          return item.mainImageUrl != "" && item.mainImageUrl != null;
        });
        if (b) {
          goodsEnabled({
            idList: this.arr,
            enabled: true
          }).then((res) => {
            this.list();
            this.dialogVisible = false;
          });
        } else {
          this.$message.error("未添加商品主图禁止上架!");
        }
      } else {
        goodsEnabled({
          idList: this.arr,
          enabled: false
        }).then((res) => {
          this.list();
          this.dialogVisible = false;
        });
      }
    },


      // 下载模板
      async downloadBtn() {
        const res = await goodsDownLoad();
        console.log('导出',res)
        let url = window.URL.createObjectURL(res); // 将二进制文件转化为可访问的url
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = "批量上传商品模板.xlsx"; //这里后台写什么后缀名格式，你就写什么
        a.click(); // 模拟点击下载
        window.URL.revokeObjectURL(url);
      },

    //导入
    uploadBtn() {
      this.$refs.file.click();
    },

    //输入框改变
    handleClick() {
      let fileObj = this.$refs.file.files[0]; // js 获取文件对象
      let formData = new FormData();
      formData.append("file", fileObj);
      goodsUpLoad(formData).then((res) => {
        this.list();
      });
    },

    // 下载模板
    async downloadBtn() {
      const res = await goodsDownLoad();
      let url = window.URL.createObjectURL(res); // 将二进制文件转化为可访问的url
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = "批量上传商品模板.xlsx"; //这里后台写什么后缀名格式，你就写什么
      a.click(); // 模拟点击下载
      window.URL.revokeObjectURL(url);
    },

  },
};
</script>



<style lang="less" scoped>
@import "../../assets/css/common.less";

.footer {
  padding: 20px;
  padding-left: 0px;
}

.body {
  height: 440px;
  padding-top: 0px;
}

.head {
  padding-top: 18px;
  padding-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.nav {
  padding-top: 13px;
  padding-bottom: 8px;
}

/deep/.OperButton {
  margin-left: 20px;
  display: inline-block;
  margin-top: 2px;
}

/deep/.el-form--inline .el-form-item__label {
  float: none;
  display: inline-block;
  font-family: PingFang;
  color: #333333;
}

.cancelBox {
  width: 126px;
  height: 42px;
  border-radius: 10px;
}

.sureBox {
  width: 126px;
  height: 42px;
  border-radius: 10px;
}

#dataOperbtn {
  width: 500px;
}

.statusColor {
  background-color: #409EFF;
  color: white;
  width: 60px;
  height: 30px;
  display: block;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
}

/ddep/ .btnBox {
  height: 64px;
}

.prompt {
  width: 60px;
  height: 60px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }

  margin-bottom: 5px;
}

#text {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}

#btnend {
  display: flex;
  width: 500px;

  div {
    margin-right: 10px;
  }

  .uploadbtn {
    width: 115px;
    height: 36px;
    background: #fc9714;
    border-radius: 10px;
  }

  .downloadbtn {
    width: 115px;
    height: 36px;
    background: #be8dfb;
    border-radius: 10px;
  }

  .groundingbtn {
    width: 115px;
    height: 36px;
    background: #18bea4;
    border-radius: 10px;

    .icontopimg {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }

  .undercarriagebtn {
    width: 115px;
    height: 36px;
    background: #ffcc33;
    border-radius: 10px;

    .icontopimg {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }

  .deletebtn {
    width: 115px;
    height: 36px;
    background: #fb6a57;
    border-radius: 10px;
  }
}

#cardfooter {
  display: flex;
  margin-top: 30px;

  .Pagination {
    margin-left: 30px;
  }
}

.el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background-color: #e4e7ed;
  z-index: 1;
}

.el-tabs__item.is-active {
  color: #0066ff;
}

/deep/.el-tabs__item {
  width: 300px;
  padding: 0px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-tabs__nav-scroll {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.main_top {
  margin-bottom: 30px;
  display: flex;

  .top1 {
    margin-top: 8px;
    width: 4px;
    height: 18px;
    background-color: #0066ff;
    margin-left: 20px;
  }

  .top2 {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 8px;
  }
}

.addbtn {
  margin-left: 0px;
  font-size: 14px;
  background-color: #18bea4;
  color: #fff;
}

#btn:hover {
  opacity: 0.7;
  cursor: pointer;
}

.top_btn {
  margin-top: 20px;
}

.mine {
  margin-top: 10px;
}

.tableHeaderColor {
  color: #fff;
}

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 100%;
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#imagesbtn {
  margin: auto;
  width: 50px;
  height: 50px;
  position: relative;

  .absPopup {
    width: 100%;
    height: 20px;
    line-height: 20px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

/deep/ .cell {
  text-align: center;
}

.el-input__inner {
  margin-right: 10px;
}

/deep/ .btnBox .el-card__body {
  padding-left: 0px;
}

/deep/ #cardfooter {
  display: flex;
  margin-top: 0px;
}

/deep/ .el-table__fixed-right::before,
.el-table__fixed::before {
  height: 0px;
}

/deep/.el-table__fixed,
.el-table__fixed-right {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, .12);
}
</style>
